<script>
import SwiperCore, {Thumbs, Navigation, Pagination} from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";


SwiperCore.use([Thumbs, Navigation, Pagination]);

import Layout from "./../layouts/main";
import appConfig from "../../app.config";

// import strategy from "@/components/profile/strategy";
import ChangePasswordForm from "@/components/profile/change-password-form";

export default {
  page: {
    title: "Profile",
    meta: [{name: "description", content: appConfig.description}],
  },
  data() {
    return {
      thumbsSwiper: null,
    };
  },
  computed: {
  },
  components: {
    Layout,
    ChangePasswordForm,
  },
  methods: {
  }
};
</script>

<template>
  <Layout>
    <div class="offset-xxl-3 col-xxl-6">
      <div class="card">
        <div class="card-header">
          <h2>
            {{ $t("t-change-password-button") }}
          </h2>
        </div>
        <div class="card-body p-4">
          <div class="tab-content">
            <div class="tab-pane active" id="changePassword" role="tabpanel">
              <ChangePasswordForm></ChangePasswordForm>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end col-->
  </Layout>
</template>
