<template>
  <form v-on:submit.prevent="submit">
    <div class="row g-2">
      <div class="col-lg-12">
        <div>
          <label for="oldpasswordInput" class="form-label">{{ $t("t-change-password-old-pass") }}*</label>
          <input type="password" class="form-control" id="oldpasswordInput"  v-model="form.old_password" required
                 :placeholder='$t("t-change-password-old-pass-hint")' />
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-12">
        <div>
          <label for="newpasswordInput" class="form-label">{{ $t("t-change-password-new-pass") }}*</label>
          <input type="password" class="form-control" id="newpasswordInput" v-model="form.new_password" required
                 :placeholder='$t("t-change-password-new-pass-hint")' />
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-12">
        <div>
          <label for="confirmpasswordInput" class="form-label">{{ $t("t-change-password-new-pass-confirm") }}*</label>
          <input type="password" class="form-control" id="confirmpasswordInput" v-model="form.confirm_password" required/>
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-12">
        <div class="mb-3 text-center">
          <router-link to="/forgot-password" class="link-primary text-decoration-underline">{{ $t("t-forgot-password") }}</router-link>
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-12">
        <div class="text-center">
          <button type="submit" class="btn btn-success">
            {{ $t("t-change-password-button") }}
          </button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </form>
</template>

<script>
import AuthService from "@/services/auth-service";
import Swal from "sweetalert2";

export default {
  name: 'ChangePasswordForm',
  data() {
    return {
      form: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      }
    }
  },
  components: {},
  methods: {
    submit() {
      if(this.form.new_password !== this.form.confirm_password) {
        Swal.fire({
          title: "New password and confirm password don't match.",
          icon: 'warning',
          timer: 5000,
          timerProgressBar: true,
        });
        return;
      }
      AuthService.changePassword(this.form.old_password, this.form.new_password).then(async (response) => {
        if (response.status === 201 && response.data.result === "success") {
          Swal.fire({
            title: "Password reset successfully",
            icon: 'success',
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          /** @TODO: error handling.Add message from server */
          Swal.fire({
            title: this.$t("t-default-error-response-message"),
            icon: 'error',
            timer: 3000,
            timerProgressBar: true,
          });
        }
        this.resetForm();
      }).catch((er) => {
        console.log(er);
        /** @TODO: error handling.Add message from server */
        Swal.fire({
          title: this.$t("t-default-error-response-message"),
          icon: 'error',
          timer: 3000,
          timerProgressBar: true,
        });
        this.resetForm();
      });
    },
    resetForm() {
      this.form.old_password = '';
      this.form.new_password = '';
      this.form.confirm_password = '';
    }
  },
};
</script>
